<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-layout wrap justify-center px-2>
      <v-flex pa-4 style="min-width:50vw">
        <v-card outlined>
          <v-layout px-8 py-4 wrap justify-center>
            <v-flex xs12 sm12 md12 text-center>
              <span style="font-size:28px;font-weight:500;color:#464646">General Project Settings</span>
            </v-flex>
          </v-layout>
          <div v-for="(item,i) in Settings" :key="i">
            <v-layout px-4 wrap align-center>
              <v-flex xs9 sm9 md7 px-2 text-left>
                <span style="font-size:18px;font-weight:500;">{{item.name}}</span>
                <!-- <v-text-field
                  v-model="item.name"
                  :disabled="true"
                  label="Name"
                  color="#00a972"
                  required
                  type="numnber"
                ></v-text-field>-->
              </v-flex>
              <v-flex xs3 sm3 md3 px-2 text-right>
                <v-text-field
                  v-model="item.value"
                  label="Value"
                  color="#00a972"
                  required
                  type="numnber"
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs1 sm1 md2>
                <v-btn
                  @click="Settings.splice(i, 1)"
                  small
                  text
                  dark
                  :ripple="false"
                  class="text-capitalize"
                  color="red"
                >
                  <span>Delete</span>
                </v-btn>
              </v-flex>-->
            </v-layout>
            <v-divider></v-divider>
          </div>
          <!-- <v-layout wrap>
            <v-flex xs12 sm12 md10 text-left>
              <v-btn
                @click="addSettings"
                small
                text
                dark
                :ripple="false"
                class="text-capitalize"
                color="#26af82"
                width="250"
              >
                <span>Add New Section</span>
              </v-btn>
            </v-flex>
          </v-layout>-->
          <v-layout px-8 py-4 wrap justify-center>
            <v-flex xs12 sm12 md12 text-center>
              <v-btn
                width="220"
                dark
                small
                color="#00a972"
                @click="updateSettings"
                class="text-capitalize"
              >Update</v-btn>
            </v-flex>
          </v-layout>
          <v-layout py-4>
            <v-flex></v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  props: ["id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      dataRetrived: false,
      coolingOfPeriod: null,
      Settings: []
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      // this.appLoading = true;

      axios({
        method: "GET",
        url: "/settings/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          this.Settings = response.data.data;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    addSettings() {
      this.Settings.push({
        name: null,
        value: null
      });
    },
    updateSettings() {
      this.appLoading = true;
      var data = {};
      data["data"] = this.Settings;
      axios({
        method: "POST",
        url: "/settings/addall",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.getData();
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    }
  }
};
</script>